@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;



h1{
  text-align: center;
  color: #555;
  margin-bottom: 0;
}



.menu_g{
position: fixed;
top:0;
width: 100%;
z-index: 1000;
background: rgba($white, 0.8);

ul{
  li{
    a{
      color:$black;
      font-size:rem-calc(18);
      transition: color 0.4s ease;
    }
    a:hover{
      color:#E5A244;
    }
  }
}
}

.powieksz{
  overflow: hidden;
width: 100%;
height: auto;
img{
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease;
}

img:hover{
  -webkit-transform: scale(1.05);
-moz-transform: scale(1.05);
-ms-transform: scale(1.05);
-o-transform: scale(1.05);
transform: scale(1.05);
}
}

.oferta{
  padding: rem-calc(20) rem-calc(0);

a{
  color: $black;
  //border: 1px solid $dark_gray;
  display: block;
  background-color: $light-gray;


}
h2{
  text-align: center;
  font-size: rem-calc(25);

  @include breakpoint(medium only) {
    font-size: rem-calc(20);
  }
}
p{
  text-align: center;
  color: #333;

}

}

.kontakt{
  background-color: #555;
  color: $white;
  padding-top: rem-calc(10);
  h3{
    text-align: center;
  }

  p{
    text-align: center;
    font-size: rem-calc(18);
    margin-top: rem-calc(10);
  }

  p.wieksze{
    font-size: rem-calc(28);
    @include breakpoint(medium only) {
      font-size: rem-calc(18);
    }
  }
}

.kontakt_g{
  background-color: #555;
  color: $white;
  padding: rem-calc(10) rem-calc(0);
  h3{
    text-align: center;
    margin: 0;
    font-size: rem-calc(28);
  }
  img{
    float: left;
    margin: rem-calc(6) rem-calc(8);
  }
  p{

    font-size: rem-calc(25);
    //margin-top: rem-calc(10);
    margin: 0;
    float: left;
    @include breakpoint(small only) {
      font-size: rem-calc(20);
    }
  }


}

.odwrotnosc {
	unicode-bidi: bidi-override;
	direction: rtl;
}

footer{
  padding: rem-calc(15);
  height: rem-calc(60);
  color: $dark-gray;
  border-top: 1px solid $dark-gray;
a{
img{
 float: right;
}
}
  @include breakpoint(small only) {

    text-align: center;
a{
img{
      //align-content: center;
     margin: rem-calc(5);
}
}

  }
}




.naglowek{

  overflow: hidden;
//margin-bottom: rem-calc(10);
  background-color: $white;

height: rem-calc(160);
background-position: center;
position: relative;

@include breakpoint(small only) {
  height: rem-calc(60);
}
@include breakpoint(medium only) {
  height: rem-calc(150);
}

  h1{
color:$white;
background-color: #555;
position: absolute;
bottom: 1%;
text-align: center;
font-size: rem-calc(50%);
//font-weight: bold;
text-align: center;
width: 100%;
margin: 0;
@include breakpoint(small only) {
    font-size: rem-calc(22);
}
  }


}

.galeria{
  padding: rem-calc(20) rem-calc(0);
a{
  color: $black;
  //border: 1px solid $dark_gray;
  display: block;
  background-color: $white;

}

p{
  text-align: center;
  color: #333;
  font-size: rem-calc(20);
}
img{
  opacity:1;

}
img:hover{
opacity: 0.8;
  //transition: opacity 0.5s linear;
}
}


.miniatury{
  //margin: rem-calc(10);

a{

  img{
    margin: rem-calc(10) rem-calc(0);

  }

  }


}

#mapa{
  width: 100%;
  height: rem-calc(500);
  @include breakpoint(medium only) {
      height: rem-calc(400);
  }
  @include breakpoint(small only) {
      height: rem-calc(250);
  }
}

hr{

   border: 0;
   height: 1px;
   margin: rem-calc(10);
   background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.title-bar{
  background: none;
  margin-left: rem-calc(20);
}

.menu-icon{
  @include hamburger($black, $dark-gray,30px, 25px, 3px);
  //margin: rem-calc(10) rem-calc(30);
}

#menu_small{

  li{
    a{
      color:$black;
      font-size:rem-calc(18);
      transition: color 0.4s ease;
      text-align: center;
    }
    a:hover{
      color:#E5A244;
    }
  }
}
